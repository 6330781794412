@tailwind base;
@tailwind utilities;
@tailwind components;

:root {
	--visible-height: calc(100vh - calc(100vh - 100%));
}

html,
body {
	padding: 0;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
		Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

	min-height: var(--visible-height);
	height: 100%;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

body > div {
	height: 100%;
}
